<script>
export default {
  name: 'FooterComponent',
  props: {
    msg: String
  }
}
</script>

<template>
<footer>
    <p>&copy; 2024 Dun Central. All rights reserved. <router-link to="/privacy-policy">Privacy Policy</router-link>.</p>
</footer>
</template>

<style scoped>
footer {
  height: 50px;
  background: #333;
  color: #fff;
  text-align: center;
  vertical-align: center;
  bottom: 0;
}
p {
  padding: 6px 0 6px 0;
}
a {
  color: whitesmoke;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
@media (max-width: 780px) {
  p {
    vertical-align: center;
    width: 100%;
    font-size: 13px;
    padding: 14px 0 14px 0;
  }
}
</style>