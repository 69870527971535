<script>
import GameList from '@/components/GameList.vue';
export default {
  name: 'GamesPage',
  components: {
    GameList,
  },
}
</script>

<template>
		<section id="games">
        <h2>Games</h2>
        <hr>
        <GameList />
		</section>
</template>

<style scoped>

</style>