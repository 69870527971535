<template>
  <div>
    <section id="about">
      <h2>About Me</h2>
      <hr>
      <p>My name is Eric Alexander Tangedal.
        I'm an ambitious Software Developer and IT technician with a passion for creating innovative and
        efficient solutions.
        I have an Associate's Degree in both Software Development and Information Technology.
        Over the years, I've gathered a lot of programming experience, working with different
        frameworks and technologies.
        I genuinely enjoy problem solving and developing software that makes a difference.
        I'm always eager to learn and grow in this ever-evolving field,
        and I'm excited about the opportunities to contribute and collaborate with others in the
        tech community.
      </p>
    </section>
    <section id="certificates">
      <h2>Certificates and Degrees</h2>
      <hr>
      <div class="certificates-container">
        <div v-for="cert in certifications" :key="cert.name" class="certificate-card mb-4">
          <div class="card h-100">
            <img :src="cert.image" class="card-img-top certificate-thumbnail" :alt="cert.name">
            <div class="card-body">
              <h5 class="card-title">{{ cert.name }}</h5>
              <p class="card-text">{{ cert.type }}</p>
              <p class="card-text"><small class="text-muted">{{ cert.date }} | {{ cert.provider }}</small></p>
              <a :href="cert.link" class="btn btn-primary" target="_blank">View Certificate</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Papa from 'papaparse';

export default {
  name: 'AboutPage',
  data() {
    return {
      certifications: []
    };
  },
  created() {
    this.fetchCertifications();
  },
  methods: {
    async fetchCertifications() {
      try {
        const response = await axios.get('/data/certificates.csv'); // Adjust the path as needed
        const csvData = response.data;
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            this.certifications = results.data;
          }
        });
      } catch (error) {
        console.error("Error fetching certifications:", error);
      }
    }
  }
};
</script>

<style scoped>
/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.certificates-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 10px 8%;
}

/* Certificate Card */
.certificate-card {
  flex: 0 1 calc(33.333% - 20px);
  box-sizing: border-box;
}

/* Card */
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

/* Card Image */
.card-img-top {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

/* Card Body */
.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

/* Card Title */
.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #FDFDFD;
}

/* Card Text */
.card-text {
  font-size: 1rem;
  color: #999;
  margin-bottom: 10px;
}

/* Button */
.btn-primary {
  align-self: flex-start;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 0.9rem;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 10px;
  font-family: "Source Code Pro Black",serif;
  color: lightblue;
  text-decoration: none;
}
</style>
