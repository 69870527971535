<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<template>
    <div class="container">
        <h1>Privacy Policy</h1>
        <h2>Introduction</h2>
        <p>Welcome to Dun Central (“we”, “our”, “us”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices regarding your personal information, please contact us at <a href="mailto:duncentral@gmail.com">duncentral@gmail.com</a>.</p>

        <h2>Information We Collect</h2>
        <p>We collect personal information that you voluntarily provide to us when you contact us through the website or sign up for any newsletters or updates.</p>
        <p><strong>Automatically Collected Information</strong></p>
        <p>When you visit Dun Central, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies installed on your device. Additionally, as you browse the site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the site, and information about how you interact with the site. We refer to this automatically collected information as “Device Information.”</p>

        <h2>How We Use Your Information</h2>
        <p>We use the information we collect in the following ways:</p>
        <ul>
            <li>To operate and maintain our website.</li>
            <li>To improve and optimize our website (for example, by generating analytics about how our visitors browse and interact with the site).</li>
            <li>To communicate with you, including responding to your comments, questions, and requests.</li>
            <li>To provide you with updates, promotions, and information about our services, provided you have opted to receive such information.</li>
        </ul>

        <h2>Google AdSense</h2>
        <p>We use Google AdSense to serve ads on Dun Central. Google may use cookies and web beacons to collect information about your browsing habits to provide you with personalized ads. You can review Google’s privacy policy and opt-out options <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">here</a>.</p>

        <h2>Sharing Your Information</h2>
        <p>We do not share your personal information with third parties except to comply with laws, to protect our rights, or to use Google AdSense as described above.</p>

        <h2>Security of Your Information</h2>
        <p>We take reasonable steps to protect your personal information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction.</p>

        <h2>Your Privacy Rights</h2>
        <p>Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete your data. To exercise these rights, please contact us at <a href="mailto:duncentral.com">contact@duncentral.com</a>.</p>

        <h2>Changes to This Policy</h2>
        <p>We may update this privacy policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or comments about this policy, you can contact us at:</p>
        <p>Email: <a href="mailto:duncentral@gmail.com">duncentral@gmail.com</a></p>
    </div>
</template>

<style scoped>
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    padding: 0;
    color: lightgray;
}
.container {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: black;
}
h1 {
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
}
h1, h2, h3 {
    color: lightgray;
}
ul {
  max-width: 60%;
  margin: 0 auto;
  padding: 20px;
}
p {
    margin-bottom: 1em;
    text-align: justify;
}
a {
    color: lightgray;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
</style>