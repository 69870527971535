import { createRouter, createWebHistory } from 'vue-router';
import AboutPage from '../components/AboutPage.vue';
import Projects from '../components/ProjectsPage.vue';
import Games from '../components/Games.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';

const routes = [
  { path: '/', name: 'About', component: AboutPage, meta: { title: 'Dun Central | About' } },
  { path: '/projects', name: 'Projects', component: Projects, meta: { title: 'Dun Central | Projects' } },
  { path: '/games', name: 'Games', component: Games, meta: { title: 'Dun Central | Games' } },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy, meta: { title: 'Dun Central | Privacy Policy' } }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Dun Central';
  next();
});

export default router;