<template>
  <div id="layout">
    <HeaderComponent/>
    <div class="content">
        <router-view></router-view>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Header.vue'
import FooterComponent from '@/components/Footer.vue'
export default {
  name: 'DunCentral',
  components: {
    HeaderComponent, FooterComponent,
  },
}
</script>

<style>
/* Global Styles */
html, #layout {
  height: 100%;
  margin: 0;
}

#layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin-top: 70px; /* navbar height */
  margin-bottom: 50px; /* footer height */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    line-height: 1.5;
    background: black;
    color: white;
}
hr {
  width: 80%;
  margin: 0 auto;
}
h2 {
  font-family: Roboto, sans-serif;
  font-size: 38px;
	padding: 20px 0 0 10%;
  margin-top: 20px;
}

p {
  font-family: "Source Code Pro", sans-serif;
  font-size: 24px;
  padding: 12px 12% 0 12%;
}

ul {
  font-family: "Source Code Pro", sans-serif;
  font-size: 24px;
}
.columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  padding: 0 12% 0 12%;
}
.column li {
  list-style-type: disc;
  margin: 12px 0 18px 24px;
}
.column {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 900px) {
  .columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .columns {
    grid-template-columns: 1fr;
  }
  h2 {
    font-family: Roboto, sans-serif;
    font-size: 28px;
  }
  p {
    font-size: 16px;
  }
  li {
    font-size: 16px;
  }
}
</style>